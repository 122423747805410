import React, { useEffect, useMemo } from "react";

import "./styles.css";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { api, auth } from "services";
import { notification } from "antd";

const Pricing = () => {
  const currentUser = useMemo(() => {
    return auth.getCurrentUser();
  }, []);

  const applyCredits = (plan) => {
    if (currentUser) {
      api
        .buyCredits(plan)
        .then((res) => {
          if (res.success) {
            notification["success"]({
              message: "Credits Applied Successfully",
              description: "You can now use this Credits in your next project",
              duration: 5,
            });
          } else if (res.error) {
            notification["error"]({
              message: "Error",
              description: res.message,
              duration: 5,
            });
          }
          console.log(res);
        })
        .catch((error) => {
          notification["error"]({
            message: "Error",
            description: error.message,
            duration: 5,
          });
        });
    }
  };
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlegetCredits = async () => {
    if (currentUser) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const data = await fetch(`${api.API_URL}razorpay/create-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount: 500 }), // amount in INR
      }).then((t) => t.json());

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Use your test Key ID
        amount: data.amount,
        currency: data.currency,
        name: "Aiensured",
        description: "Test Transaction",
        order_id: data.id,
        handler: async (response) => {
          const verifyPayment = await fetch(
            `${api.API_URL}razorpay/verify-payment`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(response),
            }
          ).then((t) => t.json());

          if (verifyPayment.status === "success") {
            applyCredits("standard");
            console.log(verifyPayment);
            notification["success"]({
              message: "Payment Successful",
              description: "Payment Received.",
              duration: 5,
            });
            alert("Payment Successful");
          } else {
            console.log(verifyPayment);
            notification["error"]({
              message: "Payment Failed!",
              description: "Payment Failed. Please try again",
              duration: 5,
            });
            alert("Payment Successful");
          }
        },
        prefill: {
          name: currentUser.get("fullname"),
          email: currentUser.get("email"),
        },
        notes: {
          address:
            "307 (Third Floor), Commerce House, Cunningham Road Bangalore, Karnataka 560052, IN",
        },
        theme: {
          color: "#3399cc",
        },
        // Specify payment methods
        method: {
          upi: true, // Enable UPI
          card: true, // Enable cards
          netbanking: true, // Enable net banking
          wallet: true, // Enable wallets
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };
  return (
    <section className="pricing py-5">
      <div className="container">
        <Row className="justify-content-center  gap-4">
          <Col lg={4}>
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">
                  Free
                </h5>
                <h6 className="card-price text-center">
                  ₹0<span className="period">/signup</span>
                </h6>
                <hr />
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    20 Credits
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    100MB Storage
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    Structured Pipeline
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    Image Pipeline
                  </li>
                  <li className="text-muted">
                    <span className="fa-li">
                      <i className="fas fa-times"></i>
                    </span>
                    Text Pipeline
                  </li>
                  <li className="text-muted">
                    <span className="fa-li">
                      <i className="fas fa-times"></i>
                    </span>
                    Unlimited Projects{" "}
                  </li>
                  <li className="text-muted">
                    <span className="fa-li">
                      <i className="fas fa-times"></i>
                    </span>
                    LifeTime Reports
                  </li>
                  <li className="text-muted">
                    <span className="fa-li">
                      <i className="fas fa-times"></i>
                    </span>
                    Monthly Status Reports
                  </li>
                </ul>
                <div className="d-grid gap-2">
                  <Button
                    onClick={() => {
                      if (!currentUser) {
                        window.location.href = "/signup";
                      } else {
                        window.location.href = "/dashboard";
                      }
                    }}
                    className="btn myButton text-uppercase"
                  >
                    {currentUser ? "Go to Dashboard" : "Sign Up"}
                  </Button>
                  <Button href="#" className="btn btn-secondary text-uppercase">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <Card className="card mb-5 mb-lg-0">
              <Card.Body className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">
                  Plus
                </h5>
                <h6 className="card-price text-center">₹599</h6>
                <hr />
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    <strong>100 Credits</strong>
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    1GB Storage
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    Structured Pipeline
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    Image Pipeline
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    Text Pipeline
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    Unlimited Projects{" "}
                  </li>
                  <li>
                    <span className="fa-li">
                      <FaCheck className="font-primary" />
                    </span>
                    LifeTime Reports
                  </li>
                  <li className="text-muted">
                    <span className="fa-li">
                      <i className="fas fa-times"></i>
                    </span>
                    Monthly Status Reports
                  </li>
                </ul>
                <div className="d-grid gap-2">
                  <Button
                    onClick={() => {
                      if (!currentUser) {
                        window.location.href = "/signup";
                      } else {
                        handlegetCredits();
                      }
                    }}
                    className="btn myButton text-uppercase"
                  >
                    {currentUser ? "Get Credits" : "Sign Up"}
                  </Button>
                  <Button href="#" className="btn btn-secondary text-uppercase">
                    Learn More
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default Pricing;
