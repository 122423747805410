import "./styles.css";
import "App.css";

import {
  CloseCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card, Form } from "react-bootstrap";
import ComponentModal from "forms/Modal";
import DragDrop from "./DragDrop";
import { Footer } from "components";
import Parse from "services/parse";
import UploadModel from "./UploadModel";
import { auth } from "services";
import { notification } from "antd";
import { Select } from "antd";
import Navigator from "../components/Navigator";

const getWidthForClassName = (className) => {
  const inputElement = document.createElement("span");
  inputElement.className = "hidden-input";
  inputElement.textContent = className;
  document.body.appendChild(inputElement);
  const width = inputElement.offsetWidth * 2 + 6; // Add some padding
  document.body.removeChild(inputElement);
  return width;
};

const Home = () => {
  const [classes, setClasses] = useState([
    {
      uploadState: false,
      className: "Class_1",
      images: [],
      width: getWidthForClassName("Class_1"),
    },
    {
      uploadState: false,
      className: "Class_2",
      images: [],
      width: getWidthForClassName("Class_2"),
    },
  ]);
  const [isDragging, setIsDragging] = useState(false);
  const [editable, setEditable] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [storedImages, setStoredImages] = useState({});
  const [progress, setProgress] = useState({ percentage: 0, completed: false });
  const [modelFile, setModelFile] = useState({ name: null, uploaded: false });
  const [loggineduser, setLoggineduser] = useState(null);
  const [canUpload, setCanUpload] = useState({
    minNumberOfImages: false,
    uploaded: false,
    uploading: false,
  });

  const CurrentUser = auth.getCurrentUser();
  const authData = CurrentUser ? CurrentUser.get("authData") : null;

  useEffect(() => {
    setCanUpload((prev) => {
      const tempVar = { ...prev, uploaded: false };
      tempVar.minNumberOfImages = classes.every(
        (item) => item.images.length >= 5
      );
      return tempVar;
    });
  }, [classes]);

  useEffect(() => {
    if (authData?.anonymous !== undefined) {
      setLoggineduser(false);
    } else if (CurrentUser) {
      setLoggineduser(true);
    } else {
      setLoggineduser(false);
    }
  }, [authData, CurrentUser]);

  const removeSpecialCharactersAndLastTwoDots = (str) => {
    const regex = /[!#()\[\]]|\.\.$/g;
    return str.replace(regex, "");
  };

  const onFileSelect = (e, classIndex) => {
    const files = e.target.files || e.dataTransfer.files;
    if (!files || files.length === 0) return;

    const newImages = Array.from(files)
      .filter((file) => file.type.split("/")[0] === "image")
      .map((file) => ({
        name: removeSpecialCharactersAndLastTwoDots(file.name),
        url: URL.createObjectURL(file),
      }));

    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].images = [
        ...newClasses[classIndex].images,
        ...newImages,
      ];
      return newClasses;
    });
  };

  const deleteImage = (classIndex, imageIndex) => {
    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].images = newClasses[classIndex].images.filter(
        (_, i) => i !== imageIndex
      );
      return newClasses;
    });
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event, classIndex) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;

    const newImages = Array.from(files)
      .filter((file) => file.type.split("/")[0] === "image")
      .map((file) => ({
        name: file.name,
        url: URL.createObjectURL(file),
      }));

    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].images = [
        ...newClasses[classIndex].images,
        ...newImages,
      ];
      return newClasses;
    });
  };

  const addNewClass = () => {
    setClasses((prevClasses) => {
      let className = null;
      if (
        prevClasses
          .map((item) => item.className)
          .includes(`Class_${prevClasses.length + 1}`)
      ) {
        className = `Class_${prevClasses.length + 2}`;
      } else {
        className = `Class_${prevClasses.length + 1}`;
      }

      return [
        ...prevClasses,
        {
          className,
          images: [],
          width: getWidthForClassName(className),
        },
      ];
    });
  };

  const handleEditClick = (index) => {
    setEditable((prev) => !prev);
    setFocusedIndex(index);
  };

  const handleClassNameChange = (e, classIndex) => {
    const newClassName = e.target.value;
    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].className = newClassName;
      newClasses[classIndex].width = getWidthForClassName(newClassName);
      return newClasses;
    });
  };

  const uploadButtonToggle = (classIndex) => {
    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].uploadState = !newClasses[classIndex].uploadState;
      return newClasses;
    });
  };

  useEffect(() => {
    if (editable && focusedIndex !== null) {
      const inputElement = document.querySelector(
        `.class_name_${focusedIndex}`
      );
      inputElement.focus();
    }
  }, [editable, focusedIndex]);

  useEffect(() => {
    localStorage.setItem("dataset", JSON.stringify(storedImages));
  }, [progress.completed]);

  const uploadPhotos = async () => {
    setCanUpload((prev) => ({ ...prev, uploading: true }));

    const totalImages = classes.reduce(
      (sum, item) => sum + (item.images ? item.images.length : 0),
      0
    );
    let uploadedImages = 0;

    setProgress((prev) => ({ ...prev, completed: false }));

    const promises = classes.map(async (classData) => {
      const classImagesPromises = classData.images.map(async (image, index) => {
        try {
          const response = await fetch(image.url);
          const blob = await response.blob();
          const parseFile = new Parse.File(image.name, blob);

          try {
            const savedFile = await parseFile.save();

            setStoredImages((prevStoredData) => {
              const newStoredData = { ...prevStoredData };
              const className = classData.className;

              if (!newStoredData[className]) {
                newStoredData[className] = { Uris: [] };
              }

              newStoredData[className].Uris[index] = savedFile.url();
              return newStoredData;
            });

            uploadedImages += 1;
            const progress = (uploadedImages / totalImages) * 100;
            updateProgressBar(progress);
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      });

      await Promise.all(classImagesPromises);
    });

    await Promise.all(promises);
    setCanUpload((prev) => ({ ...prev, uploaded: true, uploading: false }));
  };

  const updateProgressBar = (progress) => {
    setProgress((prev) => {
      const tempvar = { ...prev, percentage: Math.round(progress) };
      if (tempvar.percentage === 100) {
        tempvar.completed = true;
      }
      return tempvar;
    });
  };

  const deleteClass = (classIndex) => {
    if (classes.length > 2) {
      setClasses((prevClasses) => {
        const newClasses = [...prevClasses];
        newClasses.splice(classIndex, 1);
        return newClasses;
      });
    } else {
      notification.error({
        message: "Minimum 2 Classes are Required",
        description: "You cannot remove all the classes",
        duration: 5,
      });
    }
  };

  return (
    <div className="top_container">
      <Navigator />
      <div className="main_container">
        <div className="primary">
          {classes.map((classData, classIndex) => (
            <Card className="main_card" key={classIndex}>
              <Card.Header>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "18px",
                      marginLeft: "10px",
                    }}
                  >
                    <input
                      className={`class_name class_name_${classIndex} ${
                        editable && focusedIndex === classIndex
                          ? "edit_classname"
                          : ""
                      }`}
                      type="text"
                      accept="image/*"
                      value={classData.className}
                      disabled={!editable}
                      onChange={(e) => handleClassNameChange(e, classIndex)}
                      autoFocus={editable && focusedIndex === classIndex}
                      style={{ width: `${classData.width}px` }}
                    />
                    <EditOutlined
                      className="edit_icon"
                      onClick={() => handleEditClick(classIndex)}
                    />
                  </span>
                  {classes.length > 2 && (
                    <span
                      style={{ marginRight: "-5px" }}
                      onClick={() => deleteClass(classIndex)}
                    >
                      <CloseCircleOutlined
                        style={{
                          fontSize: "20px",
                          color: "white",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  {classData.images.length !== 0
                    ? `${classData.images.length} / 5 Images`
                    : "Add Images (Minimum 5)"}
                </Card.Title>

                <DragDrop
                  key={classIndex}
                  uploadButtonToggle={() => uploadButtonToggle(classIndex)}
                  classData={classData}
                  classIndex={classIndex}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  isDragging={isDragging}
                  onFileSelect={onFileSelect}
                  deleteImage={deleteImage}
                />
              </Card.Body>
            </Card>
          ))}
          <div className="add_class" onClick={addNewClass}>
            <PlusCircleOutlined /> Add New Class
          </div>
          <br />
          <button
            disabled={
              !canUpload.uploaded &&
              !canUpload.uploading &&
              classes.every((item) => item.images.length >= 5)
                ? false
                : true
            }
            onClick={uploadPhotos}
            className="btn btn-block btn-primary uploadBtn"
            style={{
              background:
                progress.percentage > 0 && progress.percentage < 100
                  ? `linear-gradient(to right, green ${progress.percentage}%, transparent ${progress.percentage}%)`
                  : "#a03c64",
            }}
          >
            <b>
              {!canUpload.uploaded ? (
                "Upload Images"
              ) : progress.percentage === 100 && canUpload.uploaded ? (
                <i className="bi bi-check2-circle">&nbsp; Uploaded</i>
              ) : (
                "Uploading"
              )}
            </b>
          </button>
        </div>

        <div className="model_uploadarea">
          <UploadModel setModelFile={setModelFile} modelFile={modelFile} />
          <div>
            <ComponentModal
              isDisabled={
                canUpload.uploaded &&
                canUpload.minNumberOfImages &&
                modelFile.name !== null
                  ? false
                  : true
              }
              loggineduser={loggineduser}
              className="modalswitchbutton py-5"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
