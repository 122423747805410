import { Images } from "themes";
import "../App.css";
import {
  FaXTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="footer">
      <div className="row mx-5">
        <div class="col">
          <img src={Images.logo} alt="" />
          <p className="foopara">AiEnsured is Testing Suite for AI Products.</p>
        </div>
        <div className="align-items-center col footag" align="right">
          <a href="https://x.com/testaing" target="_blank">
            <FaXTwitter className="fs-3 socialmediaicons" />
          </a>
          <a href="https://www.instagram.com/aiensured_com/" target="_blank">
            <FaInstagram className="fs-3 socialmediaicons" />
          </a>
          <a
            href="https://www.linkedin.com/company/aiensured/posts/?feedView=all"
            target="_blank"
          >
            <FaLinkedinIn className="fs-3 socialmediaicons" />
          </a>
          <a href="https://www.youtube.com/@AiEnsured" target="_blank">
            <FaYoutube className="fs-3 socialmediaicons" />
          </a>

          {/* <Facebook className="socialmediaicons" />
          <Linkdin className="socialmediaicons" />
          <Twitter className="socialmediaicons" />
          <Youtube className="socialmediaicons" /> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
