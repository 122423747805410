import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { api, auth } from "services";

const Wallet = () => {
  const [state, setState] = useState({ state: false, input: "" });
  const currentUser = auth.getCurrentUser();

  const handleClick = () => {
    console.log(currentUser.id);

    setState({ ...state, state: true });
  };

  const onSubmit = async () => {
    if (!state.input) return;
    console.log("clicked");

    await api
      .applyCoupon(state.input)
      .then((res) => {
        if (res.success) {
          notification["success"]({
            message: "Coupon Applied Successfully",
            description: "You can now use this coupon in your next project",
            duration: 5,
          });
        } else if (res.error === "coupon already used") {
          notification["error"]({
            message: "Sorry Used Coupon!",
            description: res.error,
            duration: 5,
          });
        } else if (res.error) {
          notification["error"]({
            message: "Error",
            description: res.error,
            duration: 5,
          });
        }
        console.log(res);
      })
      .catch((error) => {
        notification["error"]({
          message: "Error",
          description: error.message,
          duration: 5,
        });
      });
    setState({ state: false, input: "" });
  };

  return (
    <Row md={2} className="justify-content-center">
      <Card className="text-center">
        <Card.Header as="h5">Wallet</Card.Header>
        <Card.Body>
          <Card.Title>Your Credits</Card.Title>
          <Card.Text>
            <strong>{currentUser.get("credits")}</strong> Points
          </Card.Text>
          <Card.Footer
            className={`d-flex justify-content-around align-items-center`}
          >
            {!state.state ? (
              <div>
                <Button onClick={handleClick} className="myButton">
                  Apply Coupons
                </Button>
              </div>
            ) : (
              <div className="d-flex">
                <Form.Control
                  placeholder="Eg:- AAIEN50"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) =>
                    setState({ ...state, input: e.target.value })
                  }
                  value={state.input}
                />
                <Button onClick={onSubmit} className="myButton">
                  Submit
                </Button>
              </div>
            )}

            <div className="mt-3">
              <p className="text-muted">Updated Just Now</p>
            </div>
          </Card.Footer>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default Wallet;
