import React, { useState, useEffect } from "react";
import { Steps, Modal, notification } from "antd";
import "antd/dist/antd.css";
import { Button } from "react-bootstrap";
import { ModelInput, ModelSignUp, ModelTopic } from "forms/ModalContents";
import { auth, api } from "services";
import { signup, dashboard } from "services/paths";
import "../App.scss";
import { getRoute } from "utils";
import { Input } from "antd";

const { Step } = Steps;

const ComponentModal = ({ loggineduser, isDisabled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [password, setPassowrd] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [inputError, setInputError] = useState("");
  const [colInput, setColInput] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [hideControlBtns, setHideControlBtns] = useState({
    next: false,
    previous: false,
    pageName: "",
  });
  const steps = [
    {
      title: "Project",
      content: (
        <ModelTopic
          topic={topic}
          setTopic={setTopic}
          inputError={inputError}
          setInputError={setInputError}
        />
      ),
    },
  ];
  console.log(getRoute()); // let RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  useEffect(() => {
    console.log(loggineduser);
    if (loggineduser) {
      console.log("im Loginned");
    } else {
      console.log("Im not a user");
    }
  }, []);

  useEffect(() => {
    console.log(steps.length);
  }, [steps]);
  useEffect(() => {
    const checkInputError = () => {
      if (inputError) {
        setHideControlBtns((...prev) => ({ ...prev, next: true }));
        console.log("im in inputerror");
      } else if (!inputError) {
        setHideControlBtns((...prev) => ({ ...prev, next: false }));
        console.log("no inputerror");
      }
    };
    if (!loggineduser) {
      if (nameError || emailError || passwordError) {
        setHideControlBtns((...prev) => ({ ...prev, next: true }));
        console.log("hide btns true");
      } else {
        setHideControlBtns((...prev) => ({ ...prev, next: false }));
        console.log("hide btns false");
        if (topic) {
          checkInputError();
        }
      }
    } else {
      checkInputError();
    }
  }, [nameError, emailError, passwordError, inputError]);

  if (getRoute() === "/structured") {
    steps.push({
      title: "Column",
      content: (
        <ModelInput
          colInput={colInput}
          setColInput={setColInput}
          inputError={inputError}
          setInputError={setInputError}
        />
      ),
    });
  }
  if (!loggineduser) {
    steps.unshift({
      title: "SignUp",
      content: (
        <ModelSignUp
          email={email}
          setEmail={setEmail}
          fullName={fullName}
          setFullName={setFullName}
          setPassowrd={setPassowrd}
          password={password}
          nameError={nameError}
          setNameError={setNameError}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
        />
      ),
    });
  }

  // console.log(steps);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setTimeout(() => {
      setIsModalVisible(false);
    }, 2000);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const next = () => {
    // setInputError("");
    // setPasswordError("");
    // setEmailError("");
    // setNameError("");
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const btn = (
    <Button
      style={{ margin: 0 }}
      size="sm"
      className="myButton"
      onClick={() => (window.location = signup)}
    >
      Signup
    </Button>
  );
  console.log("im from");
  const createProjectAndRunPipeline = async () => {
    let dataset = localStorage.getItem("dataset") || null;
    let model = localStorage.getItem("model") || null;
    let project;
    if (getRoute() === "/structured") {
      console.log("structured");
      project = await api.newProject(topic, dataset, model, "structured");
      api.runImagePipeline(dataset, model, project.id, colInput, "structured");
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } else {
      console.log("image");
      project = await api.newProject(topic, dataset, model, "image");
      api.runImagePipeline(dataset, model, project.id, colInput, "image");
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    //Run pipeline
  };

  const hideModalAndNavigate = () => {
    setLoadingBtn(false);
    setIsModalVisible(false);
    localStorage.removeItem("dataset");
    localStorage.removeItem("model");
    window.location = dashboard;
  };

  const handleSubmit = async () => {
    setLoadingBtn(true);
    try {
      if (!loggineduser) {
        if (!email || !fullName) {
          throw new Error("Please Don't Leave Empty Box");
        }
        const user = auth.getCurrentUser();
        console.log(user);
        user.set("email", email);
        user.setUsername(email);
        user.set("fullname", fullName);
        user.setPassword(password);
        user.set("credits", 20);
        await user.signUp();
      }
      console.log("im running");
      await createProjectAndRunPipeline();
      hideModalAndNavigate();
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: error.message,
        duration: 5,
      });
    }
  };

  return (
    <>
      <Button
        disabled={isDisabled}
        className="StartProjectbtn myButton"
        onClick={() => {
          showModal();
        }}
      >
        Click to Proceed
      </Button>
      <Modal
        title="AiEnsured"
        maskClosable={false}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        bodyStyle={!loggineduser ? { height: 550 } : { height: 450 }}
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <hr />
        <div className="steps-content">{steps[current].content}</div>
        {!loggineduser ? (
          <>
            {" "}
            <br />
            <br />
            <br />
            <br />
          </>
        ) : null}
        <div className="steps-action">
          <br />
          {current < steps.length - 1 && (
            <Button
              // disabled={disabled}
              style={{
                float: "right",
              }}
              className="modaldonebtn myButton"
              onClick={() => next()}
              disabled={hideControlBtns.next}
            >
              {console.log("inputError", inputError)}
              Next
            </Button>
          )}
          {current > 0 && (
            <Button
              className="modaldonebtn myButton"
              style={{
                margin: "0 10px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              className={`${
                current > 0
                  ? "modaldonebtn myButton"
                  : "modalfinishbtn myButton"
              } `}
              style={{
                float: current > 0 ? "right" : null,
              }}
              onClick={handleSubmit}
              disabled={hideControlBtns.next}

              // onClick={() => WithoutLoginDone()}
              // onSubmit={afterDone}
            >
              {loadingBtn ? "Loading.." : "Done"}
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ComponentModal;
